.impressions
    h2
        text-align: center
        margin-top: 1.5rem

    h2 + .content
        margin-bottom: 3rem
        margin-top: 0

    .tns-item
        padding-left: calc(var(--bs-gutter-x) / 2)
        padding-right: calc(var(--bs-gutter-x) / 2)

    .tns-nav
        display: flex
        justify-content: center
        padding: 1.5rem 0 0

        button
            flex: 0 0 auto
            margin: 0 1rem
            height: .5rem
            width: .5rem
            padding: 0
            border-radius: 50%
            border: none
            outline: none
            background: #ccc
            transition: background-color 120ms ease, transform 180ms ease-out

            &.tns-nav-active
                transform: scale(2)
                background: darken(#ccc, 15%)