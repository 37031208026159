@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';

$primary: #210e0a;
$danger: #ffca45;
$light: #fff;

$theme-colors: (
  'primary': $primary,
  'danger': $danger,
  'light': $light
);

@import '../node_modules/bootstrap/scss/mixins';

@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/bootstrap-grid';

@import './variables';
@import './fonts';
@import './mainstyles';
@import './components';
@import './navigation';
@import './header';
@import './benefits';
@import './impressions';
@import './contact';

@import '../node_modules/tiny-slider/src/tiny-slider';