body
    color: $white
    line-height: 1.5
    font-family: $main-font
    background-color: $primary-color
    -webkit-font-smoothing: antialiased 
    -moz-osx-font-smoothing: grayscale

p, ul, ol
    font-size: 1rem
    letter-spacing: .02em

strong
    font-weight: 700

a
    text-decoration: none

    &:hover, &:active
        color: inherit

p a
    text-decoration: underline

h1, h2, h3, h4, h5, h6
    font-family: $contrast-font
    line-height: 1.125
    font-weight: 400
    letter-spacing: 0.025em
    margin-bottom: 1.5rem

h1
    font-size: 2.25rem

    @include media-breakpoint-up(sm)
        font-size: 3rem

h2
    font-size: 1.75rem
    
    @include media-breakpoint-up(sm)
        font-size: 2rem

h3
    font-size: 1.35rem

img
    max-width: 100%

.wrapper
    overflow: hidden

.content
    margin: 10rem 0 2.5rem

.subpage
    background: linear-gradient(to bottom, darken($primary-color, 4.5%) 0%, $primary-color 100%)
