.btn
    font-family: $main-font
    font-weight: 500
    letter-spacing: 0.02em

.btn-primary
    color: #000 !important
    background-color: $cta-color
    border-color: darken($cta-color, 5%)

    &:hover
        background-color: darken($cta-color, 15%)
        border-color: darken($cta-color, 10%)

    &:focus
        background-color: darken($cta-color, 15%)
        border-color: darken($cta-color, 10%)
        box-shadow: none

    &:active, &.active
        background-color: darken($cta-color, 20%)
        border-color: darken($cta-color, 15%)

.btn-link
    color: $cta-color
    &:hover
        color: darken($cta-color, 20%)

    &:focus
        color: darken($cta-color, 20%)
        box-shadow: none