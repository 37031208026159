.ctaContact
    background: darken($primary-color, 5%)
    color: $white

    .spacing
        display: inline-block
        width: 5rem
        color: $cta-color

    > div > div > div:nth-of-type(2)
        @media screen and (min-width: 992px)
            border-left: 1px dashed #fff