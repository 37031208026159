.benefits
    background: darken($primary-color, 5%)

    h2
        text-align: center
        margin: 1.5rem 0

    .item
        padding: 1.5rem 3.5rem
        margin: 1.5rem 0

        h3
            text-align: center

    img
        display: block
        margin: 0 auto 1.5rem
        width: 4rem
        max-width: 100%